.loginPage {
  background-color: #199bbe;
  color: #ffffff;
  height: 100vh;
  width: 100vw;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-top: 12%;
}

.logo {
  /* logo elem */
}

.logo img {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 30px auto;
  height: 30px;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 400px;
}

.loginForm .loginFormInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
}

.loginForm label {
  margin: 0 40px;
  font-size: 18px;
}

.loginForm .loginField {
  margin: 10px auto;
  width: 250px;
  border: none;
  padding: 5px 10px;
  color: #0e0e0e;
  font-size: 13px;
}

.loginForm .loginButton {
  background-color: #1782a2;
  border: none;
  color: #299cbc;
  padding: 5px 20px;
  width: 270px;
  margin-top: 20px;
  font-size: 14px;
  outline: 0;
}

.loginForm .loginButton:hover,
.loginForm .loginButton:active {
  background-color: #a5d750;
  color: #475710;
  cursor: pointer;
}

.error {
  color: red;
  font-weight: bold;
  width: 270px;
}

.serverMsg {
  color: white;
  margin: 22% auto 0;
  width: 250px;
  text-align: center;
  font-size: 20px;
}

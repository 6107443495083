.fullLoaderContainer {
  background: #0000001f;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullLoader {
  z-index: 40000;
}

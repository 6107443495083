@font-face {
  font-family: 'Open Sans';
  src: url('fonts/OpenSans-Regular.ttf');
  font-weight: normal;
  font-display: block;
}

@font-face {
  font-family: 'Open Sans';
  src: url('fonts/OpenSans-Bold.ttf');
  font-weight: bold;
  font-display: block;
}

@font-face {
  font-family: 'Open Sans';
  src: url('fonts/OpenSans-Light.ttf');
  font-weight: 300;
  font-display: block;
}

@font-face {
  font-family: 'Open Sans';
  src: url('fonts/OpenSans-SemiBold.ttf');
  font-weight: 600;
  font-display: block;
}

* {
  font-family: 'Open Sans';
}

html {
  font-family: 'Open Sans';
  overflow: hidden;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-widget-bg-color);
  -webkit-font-smoothing: subpixel-antialiased !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.ta-font-15-bold {
  font-size: 14px;
  font-weight: 700;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: var(--theme-blue-color);
  border-radius: 0.6rem;
}

body {
  --theme-widget-bg-color-light: rgba(255, 255, 255, 0.65);
  --theme-widget-bg-color: #212121;
  --theme-widget-title-text-color: #ffffff;
  --theme-primary-text-color: #ffffff;
  --theme-font-family: 'Open Sans';
  --theme-font-orange: #ff7e00;
  --theme-buy-color: #5a9e3b;
  --theme-sell-color: #d82a53;
  --theme-cancel-color: #ff3636;
  --theme-white-color: #ffffff;
  --theme-black-color: #000000;
  --theme-blue-color: #199bbe;
  --theme-blue-hover-color: #1eb5db;
  --theme-grey-color: #626567;
  --theme-info-color: #2196f3;
  --theme-success-color: #43a047;
  --theme-error-color: #d32f2f;
  --theme-warning-color: #ff9800;
}

/*********/
/* toggle button */
/*********/

.toggle-button {
  display: inline-flex;
  justify-content: initial;
  align-items: center;
  cursor: pointer;
}

.toggle-button .label {
  margin-right: 10px;
  order: 0;
}

.toggle-button + .toogle-button {
  margin-right: 10px;
}

.toggle-button .switch {
  display: flex;
  justify-content: initial;
  align-items: initial;
  -webkit-transition: border 0.3s;
  transition: border 0.3s;
  position: relative;
  min-width: 35px;
  width: 35px;
  height: 13px;
  order: 1;
}

.toggle-button .switch input {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.toggle-button .switch .circle {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  pointer-events: none;
  border-radius: 50%;
  background: #ced4da;
  -webkit-transform: translate(-2px, -4px);
  transform: translate(-2px, -4px);
  transition: transform 0.25s, background-color 0.3s;
}

.toggle-button .switch .circle-bg {
  width: 100%;
  border-radius: 50%;
  border-radius: 25px;
  background: var(--theme-white-color);
}

.toggle-button.checked .switch .circle {
  background: var(--theme-blue-color);
  -webkit-transform: translate(18px, -4px);
  transform: translate(18px, -4px);
}

.toggle-button.checked .switch .circle-bg {
  background: var(--theme-white-color);
}

.toggle-button.label-from-right .label {
  margin-right: 0;
  margin-left: 10px;
  order: 1;
}

.toggle-button.label-from-right .switch {
  order: 0;
}

/*********/
/* media query */
/*********/

/***************************************/
/* notistack (third-party) overrides */
/***************************************/

/* snackbar btn */
.fixer-notistack .MuiSnackbarContent-action button:hover{
    background-color: initial
}

/* snackbar close icon */
.fixer-notistack .MuiSnackbarContent-action button svg{
    width: 20px;
    height: 20px;
    fill: var(--theme-white-color)
}
/*******************/
/* ta-snackbar */
/*******************/
.ta-snackbar{
	display: flex;
	justify-content: initial;
	align-items: center;
	position: fixed;
	min-width: 300px;
	max-width: 550px;
    padding: 16px;
	z-index: 1;
    box-sizing: border-box;
	border-radius: 5px;
	animation: slidein-from-right .3s forwards
}

.ta-snackbar.close{
	animation: slideout-to-right .3s forwards
}

.ta-snackbar .btn:focus{
	outline: none
}

.ta-snackbar .btn{
	position: relative;
	padding: 0;
	margin-left: 10px;
	background-color: transparent;
    border: none;
	cursor: pointer
}

.ta-snackbar .icon.close {
    font-style: normal;
    color: var(--theme-primary-text-color);
}

.ta-snackbar .icon.close svg{
    width: 20px;
    height: 20px
}

.ta-snackbar .icon.close svg path{
    fill: currentColor
}

.ta-snackbar .icon-warpper{
	align-self: flex-start
}

.ta-snackbar .icon-warpper .icon{
	display: inline-block
}

.ta-snackbar .icon-warpper .icon svg{
	width: 20px;
    height: auto;
    margin-right: 5px;
    fill: #ffffff
}

.ta-snackbar .content{
	padding: 0;
	background: transparent
}

.ta-snackbar .content .message{
    margin: 0;
    color: var(--theme-primary-text-color);
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    border-radius: 4px;
    letter-spacing: 0.01em;
    white-space: nowrap
}

/***************/
/* variants */
/***************/
.ta-snackbar.info{
	background: var(--theme-info-color)
}

.ta-snackbar.success{
	background: var(--theme-success-color)
}

.ta-snackbar.error {
	background: var(--theme-error-color)
}

.ta-snackbar.warning{
	background: var(--theme-warning-color)
}

.ta-snackbar.default{
    background: #313131
}

/***************/
/* positions */
/***************/
.ta-snackbar.top-right{
	top: 20px;
	right: 20px
}

.ta-snackbar.top-left{
	top: 20px;
	left: 20px
}

.ta-snackbar.bottom-right{
	bottom: 20px;
	right: 20px
}

.ta-snackbar.bottom-left{
	bottom: 20px;
	left: 20px
}

/***************/
/* animations */
/***************/
@keyframes slidein-from-right{
	from {
		transform: translateX(calc(100% + 30px))
	}
	to {
		transform: translateX(0)
	}
}

@keyframes slideout-to-right{
	from {
		transform: translateX(0)
	}
	to {
		transform: translateX(calc(100% + 30px))
	}
}